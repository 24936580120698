import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

import { environment } from '../../environments/environment'; 
 

declare var jQuery:any;

@Injectable()
export class BackendService {
   public apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl; 
    constructor(private http: HttpClient,) {

        }

        uploadImage(Image){
          // const formData: FormData = new FormData();
          // formData.append('image', Image);
          //console.log(formData);

          return this.http
          .post<any>(this.RapiUrl + '/admin/Uploadimage',Image).pipe(
            map(res => {
              return res;
            }));
        }
        uploadproductImage(Image){
          const formData: FormData = new FormData();
          formData.append('image', Image);
          console.log(formData);

          return this.http
          .post<any>(this.RapiUrl + '/admin/Uploadimage',formData).pipe(
            map(res => {
              return res;
            }));
        }
        uploadImage1(Image){
          const formData: FormData = new FormData();
          formData.append('image', Image);
          console.log(formData);

          return this.http
          .post<any>(this.RapiUrl + '/admin/Uploadimage',formData , {
            reportProgress: true,
            observe: 'events'}).pipe(
            map(res => {
              return res;
            }));
        }
        //admin

        getAdminapi(eapiurl,params={}){
          return this.http
          .get<any>(this.RapiUrl + '/'+eapiurl,{params:params}).pipe(
            map(res => res));
        }

        postAdminapi(eapiurl,params={}){
          return this.http.post(this.RapiUrl + '/'+eapiurl,params).pipe(
            map(res => res));
        }

        
        // Roles Permission
        getRoleList(params:any) {
          return this.http
            .get<any>(this.RapiUrl + '/admin/role_list',{params:params}).pipe(
              map(res => res));
        }
       
        listPermissions() {
          return this.http
            .get<any>(this.RapiUrl + '/admin/listPermissions').pipe(
              map(res => res));
        }

        addEdit(role) {
          console.log(role);
             return this.http.post(this.apiUrl + '/admin/role_addedit',role).pipe(
             map(res => res));
        }

        order_list(params:any) {
          return this.http
            .get<any>(this.RapiUrl + '/admin/Orderlist',{params:params}).pipe(
              map(res => res));
        }

        // products
        product_list(params:any) {
          return this.http
            .get<any>(this.RapiUrl + '/admin/ProductList',{params:params}).pipe(
              map(res => res));
        }

        Product_CategoriesList() {
          return this.http
            .get<any>(this.RapiUrl + '/admin/ProductCategoriesList').pipe(
              map(res => res));
        }

        // Banner 
        getBanners(params={}){
          return this.http
          .get<any>(this.RapiUrl + '/admin/specieslist',{params:params}).pipe(
            map(res => res));
        }
        getcategorieslist(params={}){
          return this.http
          .get<any>(this.RapiUrl + '/admin/categorylist',{params:params}).pipe(
            map(res => res));
        }

        // report All api list 

        // get report api
        getReportList() {
          return this.http
            .get<any>(this.RapiUrl + '/report/list').pipe(
              map(res => res));
        }

        // customer name search use for report
        search(term : any='0') {
          console.log('search  term', term);
          return this.http
          .get<any>(this.RapiUrl + '/report/userlist?q='+term).pipe(
          map(res => res));
        }
        
        OfferProductList(term : any='0') {
          console.log('search  term', term);
          return this.http
          .get<any>(this.RapiUrl + '/admin/ProductListOffer?q='+term).pipe(
          map(res => res));
        }
        // gen report api 
        genReport(params={}){
          return this.http
          .post<any>(this.RapiUrl + '/Report/Genreport',params).pipe(
            map(res => res));
        }

       addeditSpecies(params){
        return this.http
        .post<any>(this.RapiUrl + '/admin/speciesaddedit',params).pipe(
          map(res => res));
       }

      //  Inventories 
        getInventoryList(params:any) {
          return this.http
            .get<any>(this.RapiUrl + '/Inventory/list',{params:params}).pipe(
              map(res => res));
        }

        // addedit Inventory
        addEditInventory(params){
          return this.http
        .post<any>(this.RapiUrl + '/Inventory/Inventoryaddedit',params).pipe(
          map(res => res));
        }

        // inventory product list
        inventoryProductList(){
          return this.http
            .get<any>(this.RapiUrl + '/inventory/ProductList').pipe(
              map(res => res));
        }

        BlogAddEdit(model){
          return this.http
          .post<any>(this.RapiUrl + '/Species/BlogAddedit', model).pipe(
            map(res => res));
        }

        BlogCategoryAddEdit(model){
          return this.http
          .post<any>(this.RapiUrl + '/Species/BlogCategoryAddedit', model).pipe(
            map(res => res));
        }


        Bloglist(params:any){
          return this.http
            .get<any>(this.RapiUrl + '/Species/BlogAdminlist',{params:params}).pipe(
              map(res => res));
        }

        CategoryAdminlist(params:any){
          return this.http
            .get<any>(this.RapiUrl + '/Species/BlogCategoryAdminlist',{params:params}).pipe(
              map(res => res));
        }

        
        Categorylist(){
          console.log('pankaj');
          return this.http
            .get<any>(this.RapiUrl + '/Species/BlogCategorylist').pipe(
              map(res => res));
        }
        customerList(params:any){
          return this.http
            .get<any>(this.RapiUrl + '/admin/Customerlist',{params:params}).pipe(
              map(res => res));
         
        }

      getlog(params:any, metadata:any){
        console.log(metadata);
        return this.http
        .get<any>(this.RapiUrl + '/'+metadata[0].apiurl, {params:params}).pipe(
          map(res => res));
      }
        


        // operartion process status update API

        getOperationapi(eapiurl,params={}){
          return this.http
          .get<any>(this.RapiUrl + '/'+eapiurl,{params:params}).pipe(
            map(res => res));
        }

        postOperationapi(eapiurl,params={}){
          return this.http.post(this.RapiUrl + '/'+eapiurl,params).pipe(
            map(res => res));
        }

        getordersaleAnalytics(params={}){
        console.log(params);
        return this.http
        .get<any>(this.RapiUrl + '/analytic/Salesorders',{params:params}).pipe(
          map(res => res));
        }

        getCampaignAnalytics(params){
        console.log(params);
        return this.http
        .get<any>(this.RapiUrl + '/analytic/Campaignreport',{params:params}).pipe(
          map(res => res));
        }
        getPageViewAnalytics(){
          return this.http
          .get<any>(this.RapiUrl + '/analytic/Pagevieweport').pipe(
            map(res => res));
        }

        getSocialTrafic(){
          return this.http
          .get<any>(this.RapiUrl + '/analytic/Socialnetworktrafic').pipe(
            map(res => res));
        }
        getDateDiffRevenue(params={}){
          return this.http
          .get<any>(this.RapiUrl + '/analytic/DateDiffRevenue',{params:params}).pipe(
            map(res => res));
        }
        getvisitlog(){
          return this.http
          .get<any>(this.RapiUrl + '/analytic/Visitlogreport').pipe(
            map(res => res));
        }
            
        getInvDashList(params){
          return this.http
            .get<any>(this.RapiUrl + '/analytic/productwiseorder',{params:params}).pipe(
              map(res => res));
        }

        // return api url
        getapiurl(){
          return this.RapiUrl;
        }

        //Add Edit Campaign Url
        addEditCampaignUrl(params){
          return this.http
        .post<any>(this.RapiUrl + '/analytic/addsaveCampaigninfo',params).pipe(
          map(res => res));
        }
        
        getCampaignUrlList(params={}) {
          return this.http
            .get<any>(this.RapiUrl + '/analytic/Listcampaign',{params:params}).pipe(
              map(res => res));
        }


         // get product list 
         getSpeciesactiveList() {
          return this.http
            .get<any>(this.RapiUrl + '/inventory/SpeciesactiveList').pipe(
              map(res => res));
        }
         // get product list 
         getProductactiveList() {
          return this.http
            .get<any>(this.RapiUrl + '/inventory/ProductsearchList').pipe(
              map(res => res));
        }
        getinvPricingList(params={}) {
          return this.http
            .get<any>(this.RapiUrl + '/Inventory/SpeciesharvestList',{params:params}).pipe(
              map(res => res));
        }
       
         //Add Edit addEditHarvestingPricing Url
        addEditHarvestingPricing(params){
          return this.http
        .post<any>(this.RapiUrl + '/inventory/addeditSpeciesharvest',params).pipe(
          map(res => res));
        }

        getcustomerOrderLogFromDB(params={}) {
          return this.http
            .get<any>(this.RapiUrl + '/order/CustomerOrderLog',{params:params}).pipe(
              map(res => res));
        }

        //get coupon code list
        getcclist(params={}){
          return this.http
            .get<any>(this.RapiUrl + '/admin/couponcodelist',{params:params}).pipe(
              map(res => res));
        }

        //Add Edit Coupon code
        addEditcc(params){
          return this.http
        .post<any>(this.RapiUrl + '/admin/couponcodeaddedit',params).pipe(
          map(res => res));
        }

        //get offer code list
        getoclist(params={}){
          return this.http
            .get<any>(this.RapiUrl + '/admin/offercodelist',{params:params}).pipe(
              map(res => res));
        }
       

        getSpecieslist(params={}){
          return this.http
            .get<any>(this.RapiUrl + '/admin/specieslist',{params:params}).pipe(
              map(res => res));
        }
        //Add Edit offer code
        addEditoc(params){
          return this.http
        .post<any>(this.RapiUrl + '/admin/offercodeaddedit',params).pipe(
          map(res => res));
        }

        potentialcustomerList(params:any){
          return this.http
            .get<any>(this.RapiUrl + '/admin/PotentialCustList',{params:params}).pipe(
              map(res => res));
         
        }

        revenuereport(params:any){
          console.log("parmas",params);
          return this.http
        .post<any>(this.RapiUrl + '/Report/Genreport',params).pipe(
          map(res => res));
   
        }
        getareacodeall_list(){
          return this.http
            .get<any>(this.RapiUrl + '/admin/areacodeall_list').pipe(
              map(res => res));
        }
        getareazoneall_list(){
          return this.http
            .get<any>(this.RapiUrl + '/admin/areazoneall_list').pipe(
              map(res => res));
        }
   }


