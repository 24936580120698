import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/providers';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(public authService:AuthService,) {
    console.log('admin layout file');
    console.log('init app');
    this.authService.refreshToken().subscribe(
        data => {
             
           //this.loading=false;
            //this.getUserProfile();
        },
        error => {
            // this.alertService.error(error);
            //this.loading = false;
        });
   }

  ngOnInit() {
    console.log('admin layout file1');
  }

}
