
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';




import { CommonService } from './index';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public commonService: CommonService,private router: Router) {

  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // this.commonService.showGlobalProgressbar();
      if (!window.navigator.onLine) {
        this.commonService.notify('error', 'Oops!', 'No Internet Connection Detected');
        // this.commonService.hideGlobalProgressbar();
      }

        // add authorization header with jwt token if available
        //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentUser=this.commonService.getCurrentUser();
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    AuthToken: `Bearer ${currentUser.token}`
                }
            });
        }

        return next
        .handle(request).pipe(
        tap((ev: HttpEvent<any>) => {
          //
          if (ev instanceof HttpResponse){
           // console.log('repsonse');
            // this.commonService.hideGlobalProgressbar();

          }
         // 
        }),
        catchError(response => {
          // this.commonService.hideGlobalProgressbar();
          if (response instanceof HttpErrorResponse) {
//            console.log('Processing http error', response.error.error.message);
            if (response.error.error.message) {
              //hide loader
              // this.commonService.hideLoader();
              // this.commonService.hideGlobalProgressbar();
              let emsg="Error Occured";
              if(response.error.error.message){
                emsg=response.error.error.message.toString().replace('Bad Request: ','');
              }

            this.commonService.notify('error', 'Oops!', emsg);
            if(response.error.error.code===401){
              if(localStorage.getItem('token')){
                localStorage.removeItem('currentUser');
                localStorage.removeItem('token');
                this.commonService.currentUser=null;
                this.commonService.apiToken=null;
               // this.router.navigate(['/auth/login']);
              }  
              this.router.navigate(['/auth/login']);
            }
          } else {
              this.commonService.notify('error', 'Oops!', 'Error Occured');
            }
          }
          return observableThrowError(response);
        }),);
    }
}
