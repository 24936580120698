import { NgModule } from '@angular/core';
import { ProgressBarComponent } from './progressbar/progressbar.component';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    exports: [ProgressBarComponent, ModalAnimationComponent,NgSelectModule],
    declarations: [ ProgressBarComponent, ModalAnimationComponent,],
    imports: [
        CommonModule,NgSelectModule]
})
export class SharedModule {}
