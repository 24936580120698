import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService,CommonService } from '../../providers';


declare interface RouteInfo {
    path: string;
    pid: Number;
    title: string;
    icon: string;
    class: string;
}
declare interface RouteInfo1 {
  path: string;
  pid: Number;
  title: string;
  icon: string;
  class: string;
}
declare interface RouteInfo2 {
  path: string;
  pid: Number;
  title: string;
  icon: string;
  class: string;
}



export const ROUTES: RouteInfo[] = [
    { 
      path: '/home/dashboard',
      pid: 38,
      title: 'Dashboard',  
      icon: 'ni-tv-2 text-primary', 
      class: '' 
    },
    // { 
    //   path: '/home/campaign', 
    //   pid: 1,
    //   title: 'Campaign URL Builder ',  
    //   icon:'ni-cart text-blue', 
    //   class: '' 
    // },
    { 
      path: '/home/orders', 
      pid: 1,
      title: 'Orders',  
      icon:'ni-cart text-blue', 
      class: '' 
    },
    { 
      path: '/home/customers', 
      pid: 5,
      title: 'Customers',  
      icon:'ni-single-02 text-orange', 
      class: '' 
    },
    { 
      path: '/home/masters', 
      pid: 9,
      title: 'Masters',  
      
      icon:'ni-bullet-list-67 text-blue', 
      class: '' 
    },
    { 
      path: '/home/reports', 
      pid: 32,
      title: 'Reports',  
      icon: 'ni-collection text-primary', 
      class: '' 
    },
    { 
      path: '/home/potential-customer', 
      pid: 5,
      title: 'Potential Customer',  
      icon:'ni-single-02 text-orange', 
      class: '' 
    },
    
];

export const ROUTESOPERATIONS: RouteInfo1[] = [
  { 
    path: '/admin/operations/dashboard', 
    pid: 45,
    title: 'Overview',  
    icon:'ni-single-copy-04 text-blue', 
    class: '' 
  },
  { 
    path: '/admin/operations/process', 
    pid: 44,
    title: 'Process',  
    icon:'ni-single-copy-04 text-blue', 
    class: '' 
  }, 
];

export const ROUTES1: RouteInfo1[] = [
  { 
    path: '/admin/blog', 
    pid: 49,
    title: 'Blog',  
    icon:'ni-single-copy-04 text-blue', 
    class: '' 
  }, 
  { 
    path: '/admin/blog_category', 
    pid: 52,
    title: 'Blog Category',  
    icon:'ni-single-copy-04 text-blue', 
    class: '' 
  }, 
  // { 
  //   path: '/admin/pages', 
  //   pid: 34,
  //   title: 'Pages',  
  //   icon:'ni-single-copy-04 text-blue', 
  //   class: '' 
  // },
  { 
    path: '/admin/banners', 
    pid: 20,
    title: 'Banners',  
    icon:'ni-pin-3 text-orange', 
    class: '' 
  },
  { 
    path: '/admin/staff', 
    pid: 24,
    title: 'Staff',  
    icon:'ni-badge text-blue', 
    class: '' 
  },
  { 
    path: '/admin/roles', 
    pid: 34,
    title: 'Roles',  
    icon:'ni-single-02 text-red', 
    class: '' 
  },
  // { 
  //   path: '/admin/inventory', 
  //   pid: 40,
  //   title: 'Inventory',  
  //   icon:'ni-money-coins text-red', 
  //   class: '' 
  // },
  // { 
  //   path: '/admin/settings', 
  //   pid: 28,
  //   title: 'System Settings',  
  //   icon:'ni-settings-gear-65 text-blue', 
  //   class: '' 
  // }
];

export const ROUTES2: RouteInfo2[] = [
  { 
    path: '/home/coupon-codes', 
    pid: 13,
    title: 'Coupon Codes',  
    icon:'ni-money-coins text-red', 
    class: '' 
  },
 { 
      path: '/home/campaign', 
      pid: 1,
      title: 'Campaign URL Builder ',  
      icon:'ni-bullet-list-67 text-blue', 
      class: '' 
  },
  { 
    path: '/home/offers', 
    pid: 13,
    title: 'Offers',  
    icon:'ni-money-coins text-red', 
    class: '' 
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public adminMenuItems: any[];
  public opMenuItems: any[];
  public marketingMenuItems : any[];
  public isCollapsed = true;

  constructor(private router: Router, public auth:AuthService, public commonService:CommonService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });

   this.adminMenuItems = ROUTES1.filter(adminMenuItems => adminMenuItems);
   this.router.events.subscribe((event) => {
     this.isCollapsed = true;
  });


  this.opMenuItems = ROUTESOPERATIONS.filter(opMenuItems => opMenuItems);
  this.router.events.subscribe((event) => {
    this.isCollapsed = true;
 });

 this.marketingMenuItems = ROUTES2.filter(marketingMenuItems => marketingMenuItems);
   this.router.events.subscribe((event) => {
     this.isCollapsed = true;
  });

  }
}
